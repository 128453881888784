<template>
  <div class="animated fadeIn">
    <b-form
      novalidate
      @submit.prevent="onSubmit"
    >
      <h4 class="mb-3">
        Редактирование доверенности ID{{ attorneyId }}
      </h4>
      <div class="form-sections">
        <div class="custom-fieldset">
          <b-row>
            <b-col md="12">
              <b-form-group
                id="driversInputGroup"
                class="form-group-el-select"
                label="Водители"
                label-for="drivers"
              >
                <el-select-clearable
                  id="drivers"
                  v-model="form.drivers.value"
                  class="form-control"
                  name="drivers"
                  placeholder="Начните ФИО водителя"
                  clearable
                  :multiple="true"
                  :multiple-limit="0"
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="driversRemote"
                  :loading="loading"
                  @clear="driversRemote('')"
                >
                  <el-option
                    v-for="item in form.drivers.options"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="attorneyNumberInputGroup"
                label="Номер доверенности"
                label-for="attorney-number"
              >
                <b-form-input
                  id="attorney-number"
                  v-model.trim="form.number"
                  type="text"
                  name="attorney-number"
                  aria-describedby="attorneyNumberLiveFeedback"
                  autocomplete="attorney-number"
                  required
                />
                <b-form-invalid-feedback id="attorneyNumberLiveFeedback">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="attorneyDateInputGroup"
                label="Дата доверенности"
                label-for="attorney-date"
              >
                <el-date-picker-input
                  id="attorney-date"
                  v-model.trim="form.date"
                  type="date"
                  name="attorney-date"
                  autocomplete="attorney-date"
                  format="dd.MM.yyyy"
                  :picker-options="pickerOptions"
                  aria-describedby="attorneyDateLiveFeedback"
                  required
                />
                <b-form-invalid-feedback id="attorneyDateLiveFeedback">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="attorneyDateStartInputGroup"
                label="Период действия с"
                label-for="attorney-date-start"
              >
                <el-date-picker-input
                  id="attorney-date-start"
                  v-model.trim="form.dateStart"
                  type="date"
                  name="attorney-date-start"
                  autocomplete="attorney-date-start"
                  format="dd.MM.yyyy"
                  :picker-options="pickerOptions"
                  aria-describedby="attorneyDateStartLiveFeedback"
                  required
                />
                <b-form-invalid-feedback id="attorneyDateStartLiveFeedback">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="attorneyDateEndInputGroup"
                label="Период действия по"
                label-for="attorney-date-end"
              >
                <el-date-picker-input
                  id="attorney-date-end"
                  v-model.trim="form.dateEnd"
                  type="date"
                  name="attorney-date-end"
                  autocomplete="attorney-date-end"
                  format="dd.MM.yyyy"
                  :picker-options="pickerOptions"
                  aria-describedby="attorneyDateEndLiveFeedback"
                  required
                />
                <b-form-invalid-feedback id="attorneyDateEndLiveFeedback">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="attorneyInputGroup"
                label="Доверенность"
                label-for="attorney"
              >
                <b-form-file
                  id="attorney"
                  v-model="form.file"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  name="attorney"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Файл не выбран"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  @input="uploadFile('file')"
                />
                <div
                  v-if="form.file.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.file.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.file.file)"
                  >{{ form.file.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="fixed-buttons">
        <b-button
          type="button"
          variant="secondary"
          class="mr-2"
          :class="{'loading' : loading}"
          @click="$router.go(-1)"
        >
          Назад
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          :class="{'loading' : loading}"
          :disabled="$v.form.$invalid || loading"
        >
          Сохранить
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import ElDatePickerInput from '../../components/ElDatePickerInput.vue';
import ElSelectClearable from '../../components/ElSelectClearable.vue';
import {validationMixin} from 'vuelidate';
import {validation} from '../../components/mixins/validation';
import {required} from 'vuelidate/lib/validators';
import moment from 'moment';
import notifications from '../../components/mixins/notifications';
import {customersContractorsAttorneyRead, customersContractorsAttorneyUpdate, contractorsDriverList, documentCreate, downloadFile} from '../../services/api';
import {Event, ServerTable} from 'vue-tables-2';

export default {
  name: 'ContractorAttorneysEdit',
  components: {
    ServerTable,
    Event,
    ElSelectClearable,
    ElDatePickerInput,
  },
  mixins: [validationMixin, notifications],
  props: ['contractorId', 'attorneyId'],
  data() {
    return {
      form: {
        drivers: {
          options: [],
          value: [],
        },
        number: '',
        date: '',
        dateStart: '',
        dateEnd: '',
        file: '',
      },
      data: {},
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      loading: false,
    };
  },
  computed: {
    formStr() {
      const calculationFormData = {};
      if (this.form.drivers.value.length) {
        calculationFormData.drivers = [];
        this.form.drivers.value.map((id) => {
          calculationFormData.drivers.push( {id: id} );
        });
      }
      if (this.form.number) {
        calculationFormData.number = this.form.number;
      }
      if (this.form.date) {
        calculationFormData.date = moment(this.form.date).format('YYYY-MM-DD');
      }
      if (this.form.dateStart) {
        calculationFormData.dateStart = moment(this.form.dateStart).format('YYYY-MM-DD');
      }
      if (this.form.dateEnd) {
        calculationFormData.dateEnd = moment(this.form.dateEnd).format('YYYY-MM-DD');
      }
      if (this.form.file) {
        calculationFormData.file = this.form.file;
      }
      return calculationFormData;
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
  },
  validations() {
    return {
      form: {
        drivers: '',
        number: {
          required,
        },
        date: {
          required,
        },
        dateStart: {
          required,
        },
        dateEnd: {
          required,
        },
        file: {
          required,
        },
      },
    };
  },
  mounted() {
    this.driversRemote('');
    this.customersContractorsAttorneyRead(this.contractorId, this.attorneyId);
  },
  methods: {
    downloadFile,
    async driversRemote(query) {
      this.loading = true;
      const response = await contractorsDriverList(this.contractorId, {
        fio: query,
        limit: 100,
      });
      if (response && response.status === 200) {
        this.form.drivers.options = response.data.items.map((item) => {
          item.name = item.driver.surname + ' ' + item.driver.name;
          if (item.driver.patronymic) {
            item.name = item.name + ' ' + item.driver.patronymic;
          }
          return {value: item.driver.id, text: item.name};
        });
      }
      this.loading = false;
    },
    async onSubmit() {
      if (this.validate()) {
        this.loading = true;
        const response = await customersContractorsAttorneyUpdate(this.contractorId, this.attorneyId, this.formStr);
        if (response && response.status === 200) {
          this.showSuccess('Доверенность обновлена');
          this.$router.push({path: '/attorneys/customer'});
        }
        this.loading = false;
      }
    },
    async customersContractorsAttorneyRead(contractorId, attorneyId) {
      this.loading = true;
      const response = await customersContractorsAttorneyRead(contractorId, attorneyId);
      if (response && response.status === 200) {
        this.form.status = response.data.status;
        this.form.drivers.options = response.data.contractorDrivers.map((contractorDriver) => {
          contractorDriver.name = contractorDriver.driver.surname + ' ' + contractorDriver.driver.name;
          if (contractorDriver.driver.patronymic) {
            contractorDriver.name = contractorDriver.name + ' ' + contractorDriver.driver.patronymic;
          }
          return {value: contractorDriver.driver.id, text: contractorDriver.name};
        });
        this.form.drivers.value = response.data.contractorDrivers.map((contractorDriver) => {
          return contractorDriver.driver.id;
        });
        this.form.number = response.data.number;
        this.form.date = response.data.date;
        this.form.dateStart = response.data.dateStart;
        this.form.dateEnd = response.data.dateEnd;
        if (response.data.file) {
          this.form.file = response.data.file;
        }
      }
      this.loading = false;
    },
    chkState(val) {
      const field = this.$v.form[val];
      if (!field.$dirty) {
        return null;
      } else if (field.$invalid) {
        return false;
      } else {
        return true;
      }
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some((child) => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
    async documentCreate(key, upload) {
      this.loading = true;
      const response = await documentCreate(upload);
      if (response && response.status === 201) {
        this.form[key] = response.data;
        this.showSuccess('Документ загружен');
      }
      this.loading = false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    uploadFile(key) {
      const file = this.form[key];
      if (file.size >= 15 * 1024 * 1024) {
        this.showError('Файл слишком большой. Максимальный объем загружаемого файла не должен превышать 15MB.');
      } else {
        this.getBase64(file).then((data) => {
          const upload = {
            base64file: data,
            originalName: file.name,
          };
          this.documentCreate(key, upload);
        });
      }
    },
  },
};
</script>

<style lang="scss">
</style>
